.homepage__title {
  text-indent: -9999px;
  display: none;
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#robotobold") format("svg");
}

.tempPaidVideo {
  margin: 10px 0;
}

.isportLogo {
  width: 50%;
}

.isportLogo img {
  width: 60%;
}

.isportTVLogo img {
  width: 40%;
}

#container #header {
  position: relative;
}

.login-container {
  color: #7b858b;
}

.login-container .user-mail {
  float: left;
  padding: 10px 5px 0 0;
  font-size: 12px;
  position: relative;
}

.login-container .login-modal, .login-container .login-modal-opener {
  float: left;
}

.login-container .dataimg-circle-head {
  width: 32px;
  height: 32px;
  display: block;
}

.login-container .dataimg-circle-head.user-login {
  width: 32px;
  height: 32px;
}

.login-container .dataimg-circle-head svg path {
  fill: #7b858b;
}

#header.header-sport-tv {
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-end;
  height: 99px;
  padding: 10px 10px 0;
  display: flex;
  position: relative;
}

#header.header-sport-tv:after, #header.header-sport-tv:before {
  content: "";
  clear: both;
  display: table;
}

#header.header-sport-tv .logo {
  text-align: left;
  width: 54%;
}

#header.header-sport-tv .logo .isportLogo img {
  width: 130px;
  height: auto;
}

#header.header-sport-tv .logo .isportTVLogo img {
  width: 95px;
  height: auto;
}

#header .header-sport-tv__right {
  float: right;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 44%;
  height: 100%;
  display: flex;
}

#header .header-sport-tv__right .login-container:after, #header .header-sport-tv__right .login-container:before {
  content: "";
  clear: both;
  display: table;
}

@media screen and (max-width: 440px) {
  #header.header-sport-tv {
    height: 90px;
  }
}

@media screen and (max-width: 380px) {
  #header.header-sport-tv {
    height: 85px;
  }

  #header.header-sport-tv .logo {
    width: 64%;
  }

  #header.header-sport-tv .header-sport-tv__right {
    width: 34%;
  }

  #header.header-sport-tv .logo .isportLogo img {
    width: 115px;
  }

  #header.header-sport-tv .logo .isportTVLogo img {
    width: 77px;
  }
}

@media screen and (max-width: 340px) {
  #header.header-sport-tv .logo .isportLogo img {
    width: 96px;
  }

  #header.header-sport-tv .logo .isportTVLogo img {
    width: 64px;
  }
}

.mobileVersion.isport-tv {
  padding-top: 0;
}

.main-menu {
  color: #fff;
  z-index: 9999;
  width: 100%;
  max-width: 480px;
  height: 100%;
  padding: 0;
  display: none;
  position: fixed;
  top: 0;
  overflow-y: scroll;
}

.main-menu__menu-open.btn--grey {
  color: #707b87;
  z-index: 9998;
  text-transform: uppercase;
  text-align: center;
  background-color: #e8edf3;
  border: 0;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.main-menu__menu-items {
  background-color: #171c20;
  padding: 20px 10px 20px 11px;
  display: block;
}

.main-menu__submenu {
  flex-wrap: wrap;
  margin: 18px 0 0;
  display: flex;
}

.main-menu__item {
  border-bottom: 1px solid #676767;
  margin-top: 12px;
  padding: 8px 0 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}

.main-menu__item--tag {
  color: #16212d;
  background-color: #f0f0f0;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin: 10px 4px 10px 11px;
  padding: 5px 9px;
  font-weight: normal;
  display: inline-block;
}

.main-menu__item--tag a {
  color: #16212d;
}

.main-menu__submenu-item {
  flex: 0 0 24%;
  margin-bottom: 8px;
  margin-right: .8%;
  font-size: 13px;
  font-weight: normal;
  line-height: 25px;
}

@media only screen and (max-width: 500px) {
  .main-menu__submenu-item {
    flex: 0 0 48%;
  }
}

@media only screen and (max-width: 300px) {
  .main-menu__submenu-item {
    flex: 0 0 98%;
  }
}

.main-menu__margin-top {
  background-color: rgba(0, 0, 0, 0);
  height: 110px;
  position: relative;
}

@media screen and (max-width: 440px) {
  .main-menu__margin-top {
    height: 100px;
  }
}

@media only screen and (max-width: 380px) {
  .main-menu__margin-top {
    height: 90px;
  }
}

.main-menu__margin-top .main-menu__close {
  z-index: 10000;
  padding: 8px 12px;
  position: absolute;
  top: 67px;
  right: 13px;
}

@media screen and (max-width: 440px) {
  .main-menu__margin-top .main-menu__close {
    top: 57px;
    right: 12px;
  }
}

@media only screen and (max-width: 380px) {
  .main-menu__margin-top .main-menu__close {
    top: 53px;
  }
}

.main-menu .btn {
  text-transform: uppercase;
  text-align: center;
  border: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.main-menu .btn--red {
  color: #fff;
  background-color: #d21e1e;
}

.main-menu .btn--black {
  color: #fff;
  background-color: #171c20;
  border-bottom: 14px solid #171c20;
}

.main-menu .btn--wide {
  width: 100%;
  margin: 0 auto;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

ul a {
  color: #fff;
}

.search-container--wide, .main-menu__close {
  background-color: #171c20;
  margin: 0;
  padding: 0 10px 30px 11px;
}

.mobileVersion .searchContainerWide.search-container--wide {
  margin: 0;
}

.btn--grey .arrow, .btn--black .arrow {
  width: 10px;
  height: 6px;
  margin-bottom: 2px;
  margin-left: 10px;
  display: inline-block;
}

.premium-sticker {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #cca550;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-family: roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  display: flex;
}

.premium-sticker:hover, .premium-sticker:focus {
  color: #fff;
  text-decoration: none;
}

.premium-sticker--inline {
  vertical-align: text-bottom;
  margin-right: 10px;
  display: inline-block;
}

.premium-sticker--small {
  padding-top: 2px;
  font-size: 11px;
  line-height: 12px;
}

.premium-sticker--small-mobile {
  line-height: 21px;
}

.premium-sticker--videoplayer-big {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 38px;
}

.list-article-normal .premium-sticker {
  margin-right: 5px;
}

@media (max-width: 760px) {
  .related-articles-list .premium-sticker {
    position: absolute;
    top: 0;
    left: 20px;
  }
}

.category-flags--big .premium-sticker {
  padding-left: 10px;
  padding-right: 10px;
}

.share-strip {
  z-index: 1000000;
  width: 100%;
  height: 40px;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.share-strip:after, .share-strip:before {
  content: "";
  clear: both;
  display: table;
}

.share-strip .share {
  text-align: center;
  cursor: pointer;
  float: left;
}

.share-strip .share.facebook {
  background-color: #4868ba;
}

.share-strip .share.twitter {
  background-color: #5db3e6;
}

.share-strip .share.whatsapp {
  background-color: #00c956;
}

.share-strip .share.e-mail {
  background-color: red;
}

.share-strip .share.e-mail .icone {
  height: 20px;
  margin: 10px auto;
}

.share-strip .share.viber {
  background-color: #7d3daf;
}

.share-strip .share.messenger {
  background-color: #0084ff;
}

.share-strip .share .icone {
  height: 24px;
  margin: 8px auto;
}

.share-strip .share .icone svg {
  height: 100%;
}

#breadcrumbs {
  color: #5b6770;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

#breadcrumbs a {
  color: #5b6770;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}

#breadcrumbs a:hover {
  color: #f4f7f8;
  text-decoration: underline;
}

#breadcrumbs span {
  text-indent: -999px;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 5 9' xmlns='http://www.w3.org/2000/svg'><path fill='#AAAAAA' d='M5,4.3L1.3,0L0,0.7l3.1,3.7L0,8.2L1.3,9L5,4.6V4.3z'/></svg>");
  background-repeat: no-repeat;
  width: 5px;
  height: 10px;
  margin: 0 5px;
  overflow: hidden;
}

#breadcrumbs h1 {
  color: #5b6770;
  margin: 0;
  font-size: 12px;
  line-height: normal;
}

body {
  text-rendering: optimizelegibility;
  color: #5b6770;
  background: #2e3a43 url("../../../images/isport-tv/bg.webp");
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

img {
  border: 0;
  max-width: 100%;
}

a {
  color: #333f48;
  cursor: pointer;
  outline: none;
  text-decoration: none !important;
}

a:hover {
  color: #dc0032;
}

h1 {
  color: #dc0032;
  clear: both;
  margin: 10px 0;
  font-size: 33px;
  font-weight: bold;
  line-height: 35px;
}

h2 {
  color: #333f48;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

h3 {
  color: #333f48;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.clear {
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.clearBorder {
  clear: both;
  border-bottom: 0;
  height: 1px;
  margin: 0 10px;
  overflow: hidden;
}

.hidden {
  display: none;
}

#container {
  min-width: 270px;
  max-width: 480px;
  margin: 0 auto;
}

#header {
  z-index: 300;
  background: #2e3a43 url("../../../images/isport-tv/bg.webp");
  width: 100%;
  min-width: 270px;
  max-width: 480px;
  padding: 10px 0 0;
  position: static;
}

#header .date {
  float: left;
  width: 25%;
  margin: 0 1%;
  padding: 10px 0;
  font-size: 12px;
}

#header .logo {
  float: left;
  text-align: center;
  width: 50%;
  display: block;
}

#header .menu {
  clear: both;
  position: relative;
}

#header .menu .shadow {
  z-index: 300;
  background: url("../../../images/isport/shadow-short.png") top no-repeat;
  width: 100%;
  height: 19px;
  display: none;
  position: absolute;
  bottom: -19px;
  left: 0;
}

#header .menu a {
  float: left;
  color: #fff;
  text-align: center;
  background: #99a4aa url("../../../images/isport/mobile/elements.png?v=27") 0 10px no-repeat;
  border-radius: 5px 5px 0 0;
  width: 23%;
  height: 30px;
  margin: 0 1%;
  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
  text-decoration: none;
  display: block;
}

#header .menu a.active {
  background: #dc0032;
}

#footer {
  clear: both;
  margin: 10px 0 0;
}

.footerInfo {
  color: #fff;
  text-align: center;
  background: #99a4aa;
  width: 96%;
  margin: 10px 0 0;
  padding: 3px 2%;
  font-size: 10px;
  font-weight: bold;
}

.displayType a {
  color: #fff;
  text-align: center;
  background: #99a4aa;
  border-radius: 5px;
  width: 50%;
  margin: 10px auto 0;
  padding: 2px 0;
  font-size: 11px;
  font-weight: bold;
  display: block;
}

.smallButton {
  text-indent: 20px;
  color: #fff;
  z-index: 55;
  cursor: pointer;
  background: url("../../../images/isport/elements.png?v=22") -310px -723px no-repeat;
  border: 1px solid #8c0222;
  border-radius: 4px;
  width: 138px;
  height: 31px;
  font-size: 16px;
  font-weight: bold;
  line-height: 31px;
  text-decoration: none;
  display: block;
  position: relative;
}

#content {
  padding: 70px 0 0;
}

.whiteContainer {
  background-color: #fff;
  border: 1px solid #d8e1e5;
  border-radius: 5px;
  margin: 0 0 10px;
  position: relative;
}

.blackContainer {
  background-color: #111;
  border: 1px solid #111;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 0 5px 5px;
  position: relative;
}

.blackContainer .categoryHeader a {
  background: #000;
}

.redContainer {
  background-color: #d80330;
  border: 1px solid #d80330;
  border-radius: 5px;
  margin: 10px 0;
  padding: 0 5px 5px;
  position: relative;
}

.redContainer .categoryHeader a {
  text-shadow: none;
  background: #d80330;
  margin-top: 0;
}

.bigPromo {
  margin: 5px;
  display: block;
  position: relative;
  overflow: hidden;
}

.bigPromo img {
  z-index: 5;
  display: block;
  position: relative;
}

.bigPromo .title {
  z-index: 20;
  color: #fff;
  background: #d80330 url("../../../images/isport/mobile/elements.png?v=27") 0 -23px no-repeat;
  width: 97%;
  margin: -35px 0 0;
  padding: 10px 2%;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  display: block;
  position: relative;
}

.smallArticle, .bigArticle, .smallPromo {
  float: left;
  text-shadow: 0 .071em .143em #fff;
  background-color: #e7e7e7;
  border-top: .071em solid #ccc;
  border-bottom: .071em solid #f9f9f9;
  width: 98%;
  padding: 5px 1%;
  display: block;
  position: relative;
}

.smallPromo .image {
  float: left;
  width: 29%;
  margin: 0 1% 0 0;
  display: block;
}

.smallArticle .title, .smallPromo .title {
  float: left;
  width: 59%;
  margin: 0 1% 0 0;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.smallArticle .title {
  width: 89%;
}

.smallArticle .catName, .smallPromo .catName {
  color: #dc0032;
  font-size: 10px;
}

.smallArticle .arrow, .smallPromo .arrow {
  float: left;
  color: #dd0032;
  text-align: center;
  line-height: 100%;
  font-size: normal;
  background: url("../../../images/isport/mobile/elements.png?v=27") 14px -195px no-repeat;
  border-radius: 5px;
  width: 10%;
  margin: 5px 0;
  padding: 0 0 5px;
  display: block;
}

.categoryHeader a, .categoryHeader span {
  color: #fff;
  text-shadow: .5px 0 2px #333f48;
  background: #dc0032;
  width: 96%;
  height: 40px;
  margin: 10px 0 0;
  padding: 0 2%;
  font-size: 25px;
  font-weight: bold;
  line-height: 40px;
  display: block;
}

.football .categoryHeader a {
  background: #5a966e;
}

.hockey .categoryHeader a {
  background: #5a64a0;
}

.tennis .categoryHeader a {
  background: #f53700;
}

#article {
  margin: 0 2%;
}

#article .categoryTitle {
  float: left;
  color: #fff;
  text-align: right;
  background: #99a4aa;
  width: 45%;
  margin: 15px 0 0;
  padding: 0 1% 0 0;
  font-size: 11px;
  font-weight: bold;
}

#article .categoryTitle a {
  color: #fff;
}

#article .datetime {
  float: left;
  color: #fff;
  background: #99a4aa;
  width: 53%;
  margin: 15px 0 0;
  padding: 0 0 0 1%;
  font-size: 11px;
  font-weight: bold;
}

#article .articleText, #article .articleText p {
  color: #333f48;
  font-size: 15px;
  line-height: 22px;
}

.galleryArticle {
  clear: both;
  float: none;
  background: #fff;
  border: 1px solid #d8e1e5;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  padding: 2px 1%;
  display: block;
}

.galleryArticle .item {
  float: left;
  width: 24%;
  margin: 0 0 0 1%;
}

.galleryArticle .item a {
  display: block;
}

.galleryArticle .title {
  color: #dc0032;
  width: 25%;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.galleryArticle .title span {
  color: #c0ced5;
  font-size: 11px;
}

#article .photoDescription {
  color: #333f48;
  clear: both;
  text-align: left;
  margin: 5px 0;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
}

#article .perex {
  clear: both;
  font-weight: bold;
}

#article .photoDescription span {
  color: #5b6770;
}

#article .imgInArt {
  clear: both;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d8e1e5;
  border-radius: 5px;
  margin: 10px 0;
  padding: 5px;
  position: relative;
}

#article table {
  background: #fff;
  border: 1px solid #d8e1e5;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 11px;
  font-size: 12px;
}

#article table td {
  text-align: left;
  padding: 2px;
}

#article table tr:nth-child(odd) {
  background: #f1f3f5;
  border-top: 1px solid #d8e1e5;
  border-bottom: 1px solid #d8e1e5;
}

.articleLink a {
  color: #fff;
  clear: both;
  text-align: center;
  background: #dc0032;
  border-radius: 5px;
  width: 96%;
  margin: 0 0 10px;
  padding: 1% 2%;
  font: bold 20px Arial, sans-serif;
  text-decoration: none;
  display: block;
  position: relative;
}

.articleLink a:hover {
  background-color: #64001e;
}

.articleLink a:hover .online {
  color: #ff3c6e;
  border-right: 1px solid #ff3c6e;
}

.articleLink a .online {
  float: left;
  color: #64001e;
  border-right: 1px solid #64001e;
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
  display: block;
}

.articleLink a .smallButton {
  clear: both;
  text-align: center;
  text-indent: 0;
  width: 100px;
  height: 26px;
  margin: 10px auto 5px;
  font-size: 14px;
  line-height: 26px;
}

.artPager {
  margin: 10px 0;
}

.artPager .pagingPrev, .artPager .pagingNext, .backToArticle {
  float: left;
  color: #fff;
  background-color: #dc0032;
  border-radius: 2px;
  margin: 1%;
  padding: 4px;
  font-size: 13px;
  font-weight: bold;
  display: block;
}

.artPager .pagingNext {
  float: right;
}

.backToArticle, .backToArticle:hover {
  float: none;
  text-align: center;
  color: #fff;
  width: 100px;
  margin: 10px auto;
}

.isportApp {
  display: none;
}

.blackLayer {
  z-index: 9999;
  color: #fff;
  background: rgba(0, 0, 0, .95);
  width: 100%;
  height: 100%;
  font-weight: bold;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.blackLayer .popUp {
  text-align: center;
  margin-top: 120px;
}

.blackLayer .popUp .buttons {
  width: 200px;
  margin: 20px auto 0;
}

.blackLayer .popUp .buttons .install {
  float: left;
}

.blackLayer .popUp .buttons .cancel {
  float: right;
}

.pagination {
  text-align: center;
  z-index: 2;
  position: absolute;
  top: 40px;
  right: 10px;
}

.pagination .swiper-pagination-switch {
  background: #fff;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  display: inline-block;
}

.pagination .swiper-active-switch {
  background: #dc0032;
}

.header {
  color: #fff;
  background: #dc0032;
  padding: 5px;
}

.header a {
  color: #fff;
  float: left;
  cursor: pointer;
  background: #dc0032;
  font-weight: bold;
}

.header .showAll {
  text-align: right;
  float: right;
  display: block;
  position: relative;
  right: 5px;
}

.swiper-container {
  width: 100%;
  margin: 0 auto;
}

.articlesSwiper {
  position: relative;
  overflow: hidden;
}

#article .headerTopImgSuperlife, #article .sectionArticleSuperlife {
  color: #fff;
  text-align: right;
  background: #dc0032;
  border: 1px solid #dc0032;
  border-bottom: 0;
  padding: 0 8px 0 0;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
  display: block;
  position: relative;
  left: 0;
}

#article .headerTopImgSuperlife {
  border-radius: 5px 5px 0 0;
  margin: 13px 0 0;
}

#article .headerTopImgSuperlife span, #article .sectionArticleSuperlife span {
  text-indent: -9000px;
  background: url("../../../images/isport/logo-superlife-small.png?v=1") 0 100% no-repeat;
  width: 128px;
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  top: 0;
}

#article .sectionArticleSuperlife {
  border-radius: 5px;
  margin: 20px 0 0;
}

#article .headerTopImgSuperlife:hover, #article .sectionArticleSuperlife:hover {
  color: #fff;
  text-decoration: underline;
}

#article .headerTopImg, #article .sectionArticle {
  color: #fff;
  text-align: right;
  background: #dc0032;
  border: 1px solid #dc0032;
  border-bottom: 0;
  padding: 0 8px 0 0;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
  display: block;
  position: relative;
  left: 0;
}

#article .headerTopImg {
  border-radius: 5px 5px 0 0;
  margin: 13px 0 0;
}

#article .headerTopImg span, #article .sectionArticle span {
  text-indent: -9000px;
  float: right;
  background: url("../../../images/isport/logo-blesk-small.png?v=1") 0 100% no-repeat;
  width: 67px;
  height: 22px;
  margin: 0 0 0 5px;
  font-size: 18px;
  font-weight: bold;
  display: block;
  position: relative;
  top: 0;
}

#article .sectionArticle {
  border-radius: 5px;
  margin: 20px 0 0;
}

#article .headerTopImg:hover, #article .sectionArticle:hover {
  color: #fff;
  text-decoration: underline;
}

.vyzvaSportu {
  position: relative;
}

.vyzvaSportu .mainLogo {
  text-align: center;
  margin: 10px 0;
}

.vyzvaSportu .count {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.vyzvaSportu .count strong {
  color: #dc0032;
  font-size: 40px;
}

.vyzvaSportu .count .red {
  color: #dc0032;
  font-size: 16px;
}

.vyzvaSportu p {
  font-size: 12px;
  line-height: 16px;
}

.vyzvaSportu .claim .number {
  float: left;
  color: #dc0032;
  text-align: right;
  width: 17px;
  font-size: 23px;
  font-weight: bold;
}

.vyzvaSportu .claim p {
  float: right;
  border-left: 2px solid #dc0032;
  width: 90%;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
}

.vyzvaSportu .smallButton {
  text-indent: 0;
  width: 220px;
  margin: 10px auto;
  background: url("../../../images/isport/elements.png?v=22") -310px -722px no-repeat !important;
  border: 1px solid #8c0222 !important;
}

.vyzvaSportu .smallButton:hover {
  color: #fff !important;
  background: #8c0222 !important;
  border-bottom: 1px solid #e3335b !important;
}

.vyzvaSportu h2 {
  margin: 0;
  color: #333f48 !important;
}

.vyzvaSportu .signature p {
  color: #333f48;
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  line-height: 16px;
}

.login form div.field {
  float: left;
  margin-right: 10px;
}

.login form label {
  color: #333f48;
  font: bold 12px / 30px Arial, sans-serif;
}

.login form div.field a {
  color: #dc0032;
  font: bold 10px / 30px Arial, sans-serif;
  text-decoration: none;
}

.login form div.field a:hover, .login form div.field a:focus {
  color: #dc0032;
  text-decoration: underline;
}

.login p {
  float: left;
  margin: 0;
  line-height: 30px;
}

.login .frmName, .login .frmPass {
  width: 160px;
}

.login .frmSubmit {
  width: 120px;
  margin-right: 0;
}

.login input.text, .login input.password {
  letter-spacing: 1px;
  color: #333f48;
  border: 1px solid #d8e1e5;
  border-radius: 5px;
  width: 148px;
  height: 22px;
  padding: 3px 5px;
  font: 16px / 22px Arial, sans-serif;
  box-shadow: inset 0 8px 10px -3px #f4f7f8;
}

.login textarea.textarea {
  letter-spacing: 1px;
  color: #333f48;
  border: 1px solid #d8e1e5;
  border-radius: 5px;
  width: 100%;
  font: 16px / 22px Arial, sans-serif;
  box-shadow: inset 0 8px 10px -3px #f4f7f8;
}

.login textarea.textarea:hover, .login input.text:hover, .login input.password:hover {
  color: #333f48;
  border-color: #b9c9d1;
}

.login textarea.textarea:focus, .login input.text:focus, .login input.password:focus {
  color: #333f48;
  border-color: #b9c9d1;
  outline: none;
}

.login input.submit {
  width: 118px;
  margin-top: 30px;
}

.links {
  border-top: 1px solid #d8e1e5;
}

.links div {
  float: right;
  width: 120px;
}

.links a {
  color: #dc0032;
  font: 12px / 30px Arial, sans-serif;
}

@media only screen and (min-height: 610px) and (orientation: portrait) {
  .flwplayer {
    height: 100%;
    width: 100% !important;
  }

  #header {
    position: fixed;
    top: 0;
  }

  #container #content {
    padding: 20px 0 0;
  }
}

@media only screen and (max-width: 320px) {
  .bigPromo .title {
    width: 99%;
    padding: 5px 1%;
  }

  .smallArticle .arrow, .smallPromo .arrow {
    background: url("../../../images/isport/mobile/elements.png?v=27") 5px -195px no-repeat;
    margin: 0;
  }
}

@media only screen and (max-width: 300px) {
  .title .catName, .title br {
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 640px), (min-width: 640px) {
  .flwplayer {
    width: 460px !important;
    height: 258px !important;
  }
}

.promoContainer {
  background-color: #dc0032;
  border: 1px solid #dc0032;
  padding: 10px 0 0;
}

.promoContainer.promoBlack {
  background-color: #111;
  border: 1px solid #111;
}

.promoContainer .articleListItem {
  border: 0 !important;
}

.promoContainer .articleContainer {
  color: #dfdfdf;
  background: url("../../../images/isport/horizontal-line-grey.png") 100% no-repeat;
  padding: 0 10px !important;
}

.promoContainer .articleContainer.last {
  background: none;
}

.promoContainer .articleContainer p, .promoContainer .articleListItem h3, .promoContainer .articleListItem h3 a {
  color: #dfdfdf;
}

.promoContainer.promoRed .inner_12.top, .promoContainer.promoOh .inner_12.top {
  margin-bottom: -10px;
}

.promoContainer.promoRed .articleContainer, .promoContainer.promoOh .articleContainer {
  background-image: none;
  border-right: 1px solid #ec6a81;
}

.promoContainer.promoRed .articleContainer.last, .promoContainer.promoOh .articleContainer.last {
  border-right: none;
}

.promoContainer.promoRed .articleContainer h3 a, .promoContainer.promoOh .articleContainer h3 a {
  color: #f4f7f8;
}

.promoContainer.promoRed .articleContainer p, .promoContainer.promoOh .articleContainer p {
  color: #f0f4f6;
}

.promoContainer.promoRed h2, .promoContainer.promoOh h2 {
  color: #fff;
  text-shadow: 0 1px #ddd;
  text-align: center;
  letter-spacing: -.06ex;
  margin-top: 0;
  font-size: 30px;
  line-height: 50px;
}

.promoContainer.promoOh h2 a {
  color: #fff;
}

.promoContainer.promoRed #supertitle, .promoContainer.promoOh #supertitle {
  color: #fff;
  text-align: center;
  background: #87001e;
  border-radius: 5px;
  height: 50px;
  font-size: 33px;
  line-height: 50px;
}

.promoContainer.promoRed #supertitle a, .promoContainer.promoOh #supertitle a {
  color: #fff;
  border-bottom: 1px solid #fff;
  text-decoration: none;
}

.promoContainer.promoRed ul.top-articles, .promoContainer.promoOh ul.top-articles {
  width: 100%;
  margin: 0;
  padding: 0 0 30px;
  list-style-type: none;
}

.promoContainer.promoRed ul.top-articles li, .promoContainer.promoOh ul.top-articles li {
  color: #fff;
  width: 75%;
  margin: 10px auto 20px;
  position: relative;
}

.promoContainer.promoRed ul.top-articles li div, .promoContainer.promoOh ul.top-articles li div {
  height: 40px;
  padding-left: 15px;
  overflow: hidden;
}

.promoContainer.promoRed ul.top-articles li:before, .promoContainer.promoOh ul.top-articles li:before {
  content: "â–¸ ";
  position: absolute;
  top: 0;
  left: -15px;
}

.promoContainer.promoRed ul.top-articles a, .promoContainer.promoOh ul.top-articles a {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}

.promoContainer.promoRed ul.top-articles a:hover, .promoContainer.promoRed ul.top-articles a:focus, .promoContainer.promoOh ul.top-articles a:hover, .promoContainer.promoOh ul.top-articles a:focus {
  border-bottom: 1px solid #fff;
}

.promoContainer.promoRed .medal {
  background: url("../../../images/isport/bigred-shadow-bottom.png") bottom no-repeat;
  padding: 0 0 20px;
}

.promoContainer.promoRed .medal h2 {
  background: url("../../../images/isport/medal-nagano.png") 0 no-repeat;
  min-height: 110px;
  padding-left: 120px;
  font-size: 80px;
}

.promoContainer.promoRed .medal h2 a {
  color: #fff;
  text-decoration: none;
}

.promoContainer.promoRed .medal #supertitle {
  font-size: 24px;
}

.promo.promoOh {
  background-color: #017eff;
  border-color: #1070d4;
}

.leagueTable {
  background: url("../../../images/isport/shadow-short.png?v=4") top no-repeat;
  width: 100%;
  position: relative;
}

.leagueTable tr.out td {
  color: #aaa;
  background-color: rgba(0, 0, 0, 0);
}

.leagueTable tr.europa-league {
  background-color: #ffeece;
}

.leagueTable tr.europa-league td {
  color: #72001a;
  background-color: rgba(0, 0, 0, 0);
}

.leagueTable tr.europa-league .iconStatus {
  text-indent: -999px;
  background: url("../../../images/isport/elements.png?v=22") -489px -287px no-repeat;
  width: 11px;
  height: 11px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.leagueTable tr.champions-league {
  background-color: #afc5da;
}

.leagueTable tr.champions-league td {
  color: #08284e;
  background-color: rgba(0, 0, 0, 0);
}

.leagueTable tr.champions-league .iconStatus {
  text-indent: -999px;
  background: url("../../../images/isport/elements.png?v=22") -488px -304px no-repeat;
  width: 13px;
  height: 12px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.noShadow .leagueTable {
  background-image: none;
}

a.panelTitle {
  color: #dc0032;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 5px;
}

a.panelTitle span {
  background: url("../../../images/isport/arrows.png") 0 -395px no-repeat;
  width: 10px;
  height: 15px;
  margin: 0 0 0 5px;
  display: inline-block;
}

a.panelTitle:hover {
  text-decoration: underline;
}

.panelFooter {
  border-radius: 0 0 3px 3px;
  width: 100%;
  padding: 1px 0;
  font-size: 10px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.panelFooter a {
  color: #dc0032;
  padding: 0 0 0 5px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.panelFooter a span {
  background: url("../../../images/isport/arrows.png") 0 -1698px no-repeat;
  width: 10px;
  height: 10px;
  margin: 0 0 0 5px;
  display: inline-block;
}

.panelFooter a:hover {
  text-decoration: underline;
}

.leagueTable .borderBottom td {
  height: 1px;
}

.leagueTable .borderBottom td div {
  border-bottom: 1px solid #5a966e;
  height: 1px;
  margin: 0 4px;
  overflow: hidden;
}

.leagueTable th {
  color: #5a966e;
  text-align: center;
  padding: 5px 0 2px;
  font-size: 11px;
  font-weight: bold;
}

.leagueTable td {
  text-align: center;
  color: #333f48;
  height: 18px;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.leagueTable td.rank {
  text-align: center;
  text-indent: 2px;
}

.leagueTable td.team {
  text-align: left;
  font-weight: bold;
}

.leagueTable td.points {
  font-weight: bold;
}

.leagueTable td a {
  color: #333f48;
  text-decoration: none;
}

.leagueTable td a:hover {
  color: #dc0032;
  text-decoration: underline;
}

.leagueTable .scoreMiddle, .leagueTable .scoreLeft, .leagueTable .scoreRight {
  float: left;
  text-align: right;
  width: 45%;
  display: block;
}

.leagueTable .scoreMiddle {
  text-align: center;
  width: 10%;
}

.leagueTable .scoreRight {
  text-align: left;
}

.leagueTable .even td {
  background: #fff;
  border-top: 1px solid #d8e1e5;
  border-bottom: 1px solid #d8e1e5;
}

.positionUp span, .positionStay span, .positionDown span {
  background: url("../../../images/isport/elements.png?v=22") -471px -290px no-repeat;
  width: 9px;
  height: 5px;
  margin: 0 auto;
  display: block;
}

.positionDown span {
  background: url("../../../images/isport/elements.png?v=22") -471px -304px no-repeat;
}

.positionStay span {
  background: url("../../../images/isport/elements.png?v=22") -471px -316px no-repeat;
}

.leagueTable .title a {
  color: #fff;
  text-align: left;
  background-color: #dc0032;
  border-radius: 5px 5px 0 0;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.leagueTable.czechTable .title a {
  background: #dc0032 url("../../../images/isport/czech-flag.png") 97% no-repeat !important;
}

.leagueTable .title a:hover {
  text-decoration: underline;
}

.resultsHeader {
  color: #fff;
  background: #dc0032;
  border-radius: 5px 5px 0 0;
  padding: 0 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 50px;
}

#article .resultsHeader {
  font-size: 16px;
  line-height: 39px;
}

.football .resultsHeader, .hockey .resultsHeader {
  background: #dc0032;
}

#article .onlineTalk h2 {
  color: #fff;
  text-align: center;
  background: #dc0032;
  border-radius: 5px;
  font-size: 33px;
  line-height: 38px;
  position: relative;
}

#article .onlineTalk .question {
  color: #333f48;
  margin: 15px 0;
  font-size: 15px;
  line-height: 20px;
}

#article .onlineTalk .question .user {
  color: #99a4aa;
}

#article .onlineTalk .answer {
  background: #fff;
  border: 1px solid #d8e1e5;
  border-radius: 5px;
  width: 100%;
  margin: 0 0 20px;
  padding: 9px;
  line-height: 20px;
  display: inline-block;
  position: relative;
}

#article .onlineTalk .chatRecords {
  border-bottom: 7px solid #dc0032;
  margin: 0 0 20px;
  padding: 0 0 13px;
}

#article .onlineTalk .answer .arrow {
  background: url("../../../images/isport/elements.png?v=22") -511px -433px no-repeat;
  width: 16px;
  height: 11px;
  position: absolute;
  top: -10px;
  left: 30px;
}

#article .onlineTalk .answer .text {
  float: right;
  color: #333f48;
  width: 80%;
  font-size: 15px;
  line-height: 20px;
}

#article .onlineTalk .showRecent .text {
  font-weight: bold;
}

#article .onlineTalk .answer .image {
  float: left;
  width: 15%;
  height: 59px;
}

#article .onlineTalk .header {
  color: #fff;
  background: #dc0032;
  border-radius: 5px 5px 0 0;
  margin: -9px -9px 10px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 39px;
}

#article .onlineTalk .messageForm {
  background: #fff;
  border-bottom: 7px solid #dc0032;
  border-radius: 5px 5px 0 0;
  margin: 20px 0;
  overflow: hidden;
}

#article .onlineTalk .messageForm form {
  border-radius: 5px 5px 0 0;
  margin: 0;
}

#article .onlineTalk form label {
  color: #333f48;
  font-size: 12px;
  font-weight: bold;
}

#article .onlineTalk form textarea {
  border: 1px solid #d3dde2;
  border-radius: 3px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding: 5px;
  box-shadow: inset 1px 1px 13px #d3dde2;
  margin-bottom: 20px !important;
}

#article .onlineTalk form {
  margin-top: 20px 0;
  border: 1px solid #d8e1e5;
  padding: 9px;
}

#article .onlineTalk .dalsiDotaz {
  padding: 10px;
}

#article .onlineTalk span.status {
  color: #ff3c6e;
  background: #64001e;
  border-radius: 3px;
  margin: 5px 0 0;
  padding: 5px;
  font-size: 18px;
  line-height: 18px;
  display: block;
  position: absolute;
  left: 4px;
}

.videoPlayerFlow {
  overflow: inherit;
  width: auto;
}

.bodyWrapper {
  background: #2e3a43 url("../../../images/isport-tv/bg.webp");
  width: 960px;
  margin: 0 auto;
  padding: 0 10px 10px;
}

.margin160Top {
  margin-top: 160px;
}

.margin70Bottom {
  margin-bottom: 70px;
}

.cb {
  clear: both;
}

p.searchError {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.subMenu li.actual a, .subMenu li, .subMenu li a:hover {
  color: #fff;
}

#navigation ul li.tv a.menuItem, #navigation ul li.tv a.menuItem:hover, #navigation ul li.inverse-item a.menuItem, #navigation ul li.inverse-item a.menuItem:hover {
  color: #99a4aa;
  background-color: #1c252c;
  background-image: none;
  background-position: 19px -99px;
  background-repeat: no-repeat;
  border: 1px solid #1c252c;
  height: 52px;
  margin-top: 0;
  line-height: 39px;
  text-decoration: none;
}

#navigation ul li.inverse-item, #navigation ul li.inverse-item a.menuItem {
  width: 90px;
}

#navigation ul li.long-item, #navigation ul li.long-item a.menuItem {
  width: 170px;
}

.centerLine {
  background: none;
}

.greyContainer, .articleFrame {
  background-color: #1c252c;
  border-radius: 5px;
  padding: 0;
  position: relative;
}

.greyContainer.noneRadiusTop {
  border-radius: 0 0 5px 5px;
}

.greyContainer.noneRadiusBottom {
  border-radius: 5px 5px 0 0;
}

#breadcrumbs a:hover {
  color: #f4f7f8;
}

.articleListItem.itemBig h2 a {
  color: #d8e1e5;
  background: none;
  min-height: auto;
  padding: 0;
  font-size: 33px;
  line-height: 35px;
}

.articleListItem.itemBig p {
  color: #d8e1e5;
  font-size: 15px;
  line-height: 22px;
}

.articleListItem.itemBig .videoWrapper, .articleListItem.itemMiddle .videoWrapper {
  width: auto;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
}

.articleListItem .videoWrapper {
  width: 140px;
  height: 100px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.articleListItem.itemMiddle .videoWrapper.fixHeight {
  height: 180px;
}

.articleListItem.itemBig .videoWrapper .overlay {
  position: absolute;
  top: 135px;
  left: 260px;
}

.articleListItem.itemBig .superTitle {
  margin: 0;
  line-height: 17px;
}

.articleListItem.itemBig .superTitle a {
  color: #d8e1e5;
  text-decoration: underline;
}

.articleListItem.itemBig .superTitle a:hover {
  color: #fff;
  text-decoration: underline;
}

.articleListItem.itemBig .datetime {
  color: #99a4aa;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
}

.articleListItem.itemBig .description {
  color: #dee6ee;
  margin-top: 0;
  margin-right: 20px;
}

.articleListItem {
  border-bottom: 0;
  height: 110px;
  padding: 119px 0 0;
  position: relative;
  overflow: hidden;
}

.articleListItem h2.title {
  font-weight: normal;
}

.articleListItem.itemMiddle h2.title {
  font-weight: bold;
}

.articleListItem.itemMiddle {
  height: 250px;
  padding: 0;
}

.articleListItem a.articlePhoto {
  position: static;
}

.articleListItem h2 a {
  color: #fff;
  text-decoration: none;
}

.articleListItem h2 a:hover {
  color: #fff;
  text-decoration: underline;
}

.articleListItem.itemMiddle .videoWrapper .redLine {
  color: #fff;
  background: #dc0032;
  width: 280px;
  height: 19px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 19px;
  position: absolute;
  bottom: 0;
}

.articleListItem.itemMiddle .videoWrapper .redLine .category {
  float: left;
}

.articleListItem.itemMiddle .videoWrapper .redLine .time, .articleListItem.itemMiddle .videoWrapper .redLine .views {
  float: right;
  background: #64001e;
  border-radius: 5px;
  margin: 3px 5px;
  padding: 0 5px;
  font-size: 11px;
  line-height: 14px;
}

.articleListItem.itemMiddle .videoWrapper .redLine .views {
  background: #64001e url("../../../images/isport/tv/ico-eye.png") 2px 3px no-repeat;
  margin-right: 0;
  padding: 0 5px 0 20px;
}

.articleListItem.itemMiddle .icon {
  background: url("../../../images/isport-tv/icon-play.png") no-repeat;
  width: 38px;
  height: 38px;
  position: absolute;
  top: -27px;
  left: 140px;
}

.articleListItem.itemMiddle h2, .articleListItem.itemMiddle h2 a {
  margin: 10px 0;
}

.articleListItem.itemBig .articleContainer {
  margin: 0;
  padding: 0 0 25px;
  position: relative;
}

.menuBottom {
  color: #99a4aa;
  background: url("../../../images/isport/tv/shadow-bottom.png?v=7") 160px 34px no-repeat;
  border-bottom: 1px solid #5b6770;
  margin-bottom: 10px;
  padding: 0;
  display: block;
}

.menuBottom .title {
  float: left;
  padding: 10px 10px 0;
  font-size: 35px;
  font-weight: bold;
}

.menuBottom .title a {
  color: #99a4aa;
  text-decoration: none;
}

.menuBottom .title a:hover {
  color: #fff;
  text-decoration: none;
}

.menuBottom .videoCategories li a {
  color: #dee6ee;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
  border-bottom: none;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
  text-decoration: none;
  display: inline-block;
}

.menuBottom .videoCategories li {
  float: left;
  height: 30px;
  margin: 3px 25px 0 0;
  padding: 0 2px;
  font-size: 14px;
}

.menuBottom .videoCategories li.highlight {
  background: #dc0032;
  border-radius: 5px;
}

.menuBottom .videoCategories {
  float: left;
  margin: 15px 5px 0 0;
  padding-left: 5px;
  list-style: none;
}

.loadMore {
  color: #333f48;
  height: 51px;
  margin-bottom: 0;
  font-size: 10px;
  text-decoration: none;
  display: block;
  position: relative;
}

.wraperLoader .loadMore {
  top: -51px;
}

.loadMore .shadow {
  background: url("../../../images/isport/tv/shadow-bottom.png?v=7") 0 29px no-repeat;
  width: 802px;
  height: 50px;
  margin-left: 160px;
  display: block;
}

.searchLoad {
  height: 50px;
}

.loadMore a.load {
  float: left;
  text-align: center;
  background-color: #8b97a4;
  border: 1px solid #0f1316;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  width: 198px;
  height: 25px;
  font-size: 11px;
  font-weight: bold;
  line-height: 25px;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: -27px;
  left: 380px;
  color: #1c252c !important;
}

.articleDetail p {
  margin: 0 0 20px;
}

.articleDetail {
  color: #d8e1e5;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 20px;
  top: 0;
}

.articleDetail h1 {
  color: #d8e1e5;
  font-size: 33px;
  font-weight: bold;
  line-height: 35px;
}

.articleDetail .views {
  float: left;
  color: #99a4aa;
  background: #1c252c url("../../../images/isport/tv/ico-eye.png") 2px 3px no-repeat;
  border-radius: 5px;
  margin: 10px 0;
  padding: 0 5px 0 20px;
  font-size: 11px;
  line-height: 14px;
}

.articleDetail #article .datetime {
  color: #99a4aa;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.relatedVideo {
  background: #1c252c;
  border-radius: 5px;
}

.relatedVideo a {
  text-decoration: none;
}

.relatedVideo .image {
  float: left;
}

.relatedVideo .title a {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.relatedVideo .title a:hover {
  text-decoration: underline;
}

.relatedVideo .detailInfo {
  float: right;
  color: #666c71;
  border-left: 1px solid #2f383f;
  width: 119px;
  margin: 10px 0 0;
  padding: 0 10px;
  font-size: 12px;
}

.relatedVideo .detailInfo .category {
  color: #dc0032;
  margin: 20px 0 0;
  font-size: 10px;
  font-weight: bold;
}

.relatedVideo .detailInfo .category a:hover {
  color: #dc0032;
  text-decoration: underline;
}

.relatedVideo .detailInfo .link {
  color: #666c71;
  font-size: 10px;
}

.relatedVideo .detailInfo .link:hover {
  color: #fff;
  text-decoration: underline;
}

.socialSharing {
  float: left;
  width: auto;
  margin: 0 20px 10px 0;
  padding: 10px 0 0;
  display: block;
}

.video__description {
  text-align: justify;
}

.articleListItem.itemMiddle h2 a {
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
}

.articleListItem.itemBig .articleContainer.topArticles {
  border-bottom: 0 solid #5b6770;
  margin: 0;
  padding: 0 0 10px;
  position: relative;
}

.articleListItem.itemBig .articleListItem.itemMiddle {
  height: auto;
}

p.topArticlesTitle {
  color: #fff;
  height: 30px;
  margin-top: 0;
  font-size: 20px;
}

.positionTop-30 {
  position: relative;
  top: -30px;
}

.playerWrapper {
  background: url("../../../images/isport/tv/video-player-loader.gif") center no-repeat;
  min-height: 348px;
  margin-bottom: 20px;
}

.mobileVersion .menuBottom {
  color: #99a4aa;
  z-index: 500;
  background: url("../../../images/isport/tv/menu-bg.jpg");
  border-top: 1px solid #5c5c5c;
  border-bottom: 1px solid #5c5c5c;
  max-width: 480px;
  margin-bottom: 20px;
  padding: 10px;
  display: none;
  position: relative;
  top: -6px;
  overflow: hidden;
}

.mobileVersion .menuBottom ul {
  width: 450px;
  margin: 5px;
  padding: 0;
}

.mobileVersion .menuBottom ul li {
  margin: 0 6px;
}

.mobileVersion .menuBottom ul li.last a {
  border-bottom: none;
}

.mobileVersion .articleListItem.itemMiddle, .mobileVersion .articleListItem.itemMiddle a img {
  width: 100%;
  height: auto;
  padding: 0;
}

.mobileVersion .articleListItem.itemMiddle .redLine {
  width: 100%;
  margin: 0;
  padding: 0;
}

.mobileVersion .articleListItem.itemMiddle .redLine .category {
  margin-left: 10px;
}

.mobileVersion .articleListItem.itemMiddle .redLine .views {
  margin-right: 10px;
}

.mobileVersion .articleListItem.itemMiddle .icon {
  left: 45%;
}

.mobileVersion .articleListItem.itemMiddle h2.title {
  margin: 10px 10px 20px;
  font-weight: bold;
}

p.error.emptyCategory {
  color: #fff;
  padding: 10px 10px 30px;
  font-weight: bold;
}

p.error.noSearchResults {
  color: #fff;
  background-color: #66b771;
  border: 1px solid #038615;
  padding: 15px 10px;
  font-weight: bold;
}

.searchPhrase {
  color: #505050;
}

h3.boxTitle {
  color: #99a4aa;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

.greyBorder {
  border-bottom: 1px solid #5b6770;
}

#navigation ul li.tv a {
  z-index: 2;
  margin-top: 0;
  position: relative;
  bottom: -1px;
}

.mobileVersion {
  padding-top: 20px;
  position: relative;
}

.facebookToggle {
  color: #99a4aa;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.articleListItem.itemBig {
  overflow: visible;
}

#dejte-nam-tip-form {
  padding: 20px 40px;
}

#dejte-nam-tip-form label {
  color: #99a4aa;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  text-decoration: none;
  display: block;
}

#dejte-nam-tip-form #content-text {
  resize: none;
  width: 870px;
  height: 120px;
  padding: 5px;
}

#chyba {
  padding: 20px 40px 0;
}

#captcha-text {
  display: none;
}

.searchContainer.bottomMenu {
  width: 155px;
  margin: 12px 10px 10px;
}

.searchContainer.bottomMenu form {
  float: right;
  width: 155px;
  height: 32px;
  margin: 0;
}

.searchContainer.bottomMenu .searchInput {
  float: left;
  color: #7b858b;
  background: url("../../../images/isport/elements.png?v=77") -264px -229px no-repeat;
  border: 0;
  width: 106px;
  height: 32px;
  padding: 0 5px;
}

.searchContainerWide {
  float: left;
  text-align: left;
  width: 620px;
}

.searchContainerWide form {
  float: left;
  width: 620px;
  height: 35px;
  margin: 5px 0;
}

.searchContainerWide .searchInput {
  float: left;
  color: #000;
  background: #fff;
  border: 0;
  width: 499px;
  height: 35px;
  padding: 0 5px;
  font-size: 18px;
}

.searchContainerWide .searchSubmit {
  cursor: pointer;
  float: left;
  text-indent: -999px;
  background: url("../../../images/isport-tv/search-button.webp") no-repeat;
  border: 0;
  width: 111px;
  height: 35px;
  padding: 0;
  overflow: hidden;
}

.searchContainerWide .searchSubmit:hover {
  background: url("../../../images/isport-tv/search-button.webp") 0 -37px no-repeat;
}

.noSearchResults {
  color: #fff;
  font-size: 22px;
}

a.poskytovatel {
  color: #99a4aa;
  text-align: center;
  background-color: #1c252c;
  border-radius: 3px;
  margin: 7px 0;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

a.poskytovatel:hover {
  background-color: #333;
}

.menuToggle {
  cursor: pointer;
  z-index: 501;
  background: url("../../../images/isport/tv/menu-closed.png") no-repeat;
  width: 106px;
  height: 30px;
  display: block;
  position: absolute;
  top: -30px;
  right: 5px;
}

.menuToggle.menuOpened {
  height: 35px;
}

.mobileVersion .menuBottom .searchContainerWide {
  width: 460px;
}

.mobileVersion .searchContainerWide {
  width: 100%;
  margin: 10px 0;
}

.mobileVersion .searchContainerWide .searchInput {
  width: 60%;
  margin-left: 0;
}

.mobileVersion .menuBottom .searchContainerWide .searchInput {
  width: 260px;
  margin-left: 40px;
}

#content.mobileVersion {
  padding-top: 10px;
}

.mobileVersion .loadMore .shadow {
  background: url("../../../images/isport/tv/shadow-bottom.png?v=7") -90px 0 no-repeat;
  width: 100%;
  height: 21px;
  margin-left: 0;
  display: block;
}

.mobileVersion .loadMore a.load {
  float: left;
  text-align: center;
  background-color: #dc0032;
  border: 1px solid #0f1316;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  width: 198px;
  height: 25px;
  margin: auto;
  font-size: 13px;
  font-weight: bold;
  line-height: 25px;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 4px;
  left: 4%;
  right: 4%;
  color: #fff !important;
}

.mobileVersion .loadMore a.load.loading {
  color: #dc0032 !important;
}

.mobileVersion .searchContainerWide form {
  width: 100%;
}

.mobileVersion .menuBottom .videoCategories li a {
  color: #dee6ee;
  text-align: left;
  border-bottom: 1px solid #dee6ee;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
}

.mobileVersion .socialSharing {
  float: none;
}

.mobileVersion .date {
  float: left;
  color: #99a4aa;
  background: #1c252c;
  border-radius: 5px;
  margin: 10px 10px 10px 0;
  padding: 0 5px;
  font-size: 11px;
  line-height: 14px;
}

.mobileVersion #article {
  margin: 0;
}

.backToCategory {
  color: #fff;
  text-align: center;
  background: #dc0032;
  margin: 10px 0;
  padding: 5px;
}

.backToCategory a {
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.mobileVersion .menuBottom .videoCategories {
  float: left;
  margin: 20px 20px 15px 0;
  padding-left: 0;
  list-style: none;
}

.mobileVersion .menuBottom .videoCategories li {
  float: none;
  margin: 0 6px;
  font-size: 15px;
}

.mobileVersion .menuBottom .videoCategories li.actual a {
  color: #fff;
}

.embedVideo {
  color: #99a4aa;
  text-align: center;
  cursor: pointer;
  float: none;
  background-color: #1c252c;
  border-radius: 3px;
  margin: 7px 10px 7px 0;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.embed400, .embed550, .embed640, .embedAuto {
  color: #99a4aa;
  text-align: center;
  cursor: pointer;
  float: left;
  background-color: #1c252c;
  border-radius: 3px;
  width: 80px;
  margin: 7px 10px 7px 0;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  display: none;
}

.embedAuto {
  width: 140px;
}

.embedAutoInput {
  float: left;
  color: #fff;
  background: #1c252c;
  border: 1px solid #000;
  width: 160px;
  padding: 10px;
  font-size: 14px;
  display: none;
}

.embedCode {
  color: #fff;
  background: #1c252c;
  border: 1px solid #000;
  width: 600px;
  margin: 10px 0;
  padding: 10px;
  display: none;
}

.stream.streamFloat {
  float: left;
  width: 470px;
  height: 300px;
  overflow: hidden;
}

.stream.streamFloat.streamFloatRight {
  float: right;
}

.stream .androidPlayer img {
  width: 620px;
}

.stream .video {
  width: 620px;
  position: relative;
}

.stream .video .minutes {
  color: #c0ced5;
  font-family: Open Sans Bold, Arial, sans-serif;
  font-size: 25px;
  display: block;
  position: absolute;
  top: -35px;
  left: 0;
}

.stream .video .title {
  color: #fff;
  box-sizing: border-box;
  z-index: 5;
  background-image: url("../../../images/isport-synotliga/bg-title.png");
  width: 100%;
  padding: 5px 10px;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 14px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.stream .video img {
  width: 620px;
  display: block;
}

.stream .video .play {
  z-index: 5;
  opacity: .5;
  background-image: url("../../../images/isport-synotliga/sprites.png");
  background-position: 0 -100px;
  width: 60px;
  height: 60px;
  transition: all .5s;
  position: absolute;
  top: 150px;
  left: 280px;
}

.stream .video.odd .minutes {
  left: auto;
  right: 0;
}

.stream .video:hover .play {
  opacity: 1;
}

.streamList {
  background: #1c252c;
  border-radius: 5px;
  min-height: 350px;
  margin: 0 0 20px;
}

.streamList h3 {
  color: #d8e1e5;
  text-align: center;
  padding: 19px;
  font-size: 18px;
}

.streamList .streamItem {
  color: #99a4aa;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
}

.streamList .streamItem.active {
  color: #fff;
  background: #2c353c;
}

.streamList .streamItem.deactive {
  color: #5e6569;
  background: #11171b;
}

.streamList .streamItem.deactive span {
  color: #99a4aa;
}

.streamList .streamItem span {
  color: #d8e1e5;
}

.streamList a.streamItem {
  padding: 13px;
  text-decoration: none;
  display: block;
}

.streamList a.streamItem:hover {
  text-decoration: none;
}

.streamList a.streamItem p {
  color: red;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: block;
}

.streamList a.streamItem:hover p {
  text-decoration: underline;
}

.streamHead {
  background: url("../../../images/isport/pohar-facr/stream-head-bg.png") 0 0 no-repeat;
  width: 620px;
  height: 100px;
  display: block;
}

.streamHead .streamHome, .streamHead .streamAway {
  color: #d8e1e5;
  background-repeat: no-repeat;
  min-width: 100px;
  height: 100px;
  font-size: 25px;
  font-weight: bold;
  line-height: 100px;
  display: inline-block;
}

.streamHead .streamHome {
  text-align: left;
  float: left;
  background-position: 0 0;
  margin: 0 0 0 80px;
  padding: 0 0 0 75px;
}

.streamHead .streamAway {
  text-align: right;
  float: right;
  background-position: right 0;
  margin: 0;
  padding: 0 75px;
}

.streamHead .streamVs {
  color: #6b7378;
  text-align: center;
  min-width: 35px;
  height: 100px;
  font-size: 25px;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 45px;
  left: 330px;
}

.streamHead .slovacko {
  background-image: url("../../../images/isport/pohar-facr/slovacko.png");
}

.streamHead .boleslav {
  background-image: url("../../../images/isport/pohar-facr/boleslav.png");
}

.streamHead .sparta {
  background-image: url("../../../images/isport/pohar-facr/sparta.png");
}

.streamHead .jablonec {
  background-image: url("../../../images/isport/pohar-facr/jablonec.png");
}

.streamHead .liberec {
  background-image: url("../../../images/isport/pohar-facr/liberec.png");
}

.streamHead .teplice {
  background-image: url("../../../images/isport/pohar-facr/teplice.png");
}

.streamHead .pardubice {
  background-image: url("../../../images/isport/pohar-facr/pardubice.png?v=1");
}

.streamHead .plzen {
  background-image: url("../../../images/isport/pohar-facr/plzen.png");
}

.titleStream {
  text-align: center;
  color: #aaa;
  background: #323232;
  border: 1px solid #323232;
  margin-top: 10px;
  padding: 7px 2px 10px;
  font-size: 30px;
  display: block;
}

.titleStream:hover {
  color: #323232;
  background: #aaa;
  border: 1px solid #323232;
  padding: 7px 2px 10px;
}

.titleLink {
  color: #ddd;
  display: block;
  text-decoration: underline !important;
}

.titleStream:hover .titleLink, .titleLink:hover {
  color: #323232;
}

.error404 {
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
}

.error404 .status {
  text-transform: uppercase;
  padding: 30px 0 10px;
  font-size: 30px;
  line-height: 30px;
}

.error404 .apologise {
  padding: 10px;
  font-size: 18px;
  line-height: 22px;
}

.error404 .colorBtn {
  color: #fff;
  background: #dc0032;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.relatedVideos {
  z-index: 105;
  background: rgba(45, 45, 45, .9);
  border: 0 solid #a00;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.videoInArticle .relatedVideos {
  width: 440px;
  height: 248px;
}

.articleVideoTop .relatedVideos {
  width: 620px;
  height: 349px;
}

.relatedVideos.hidden {
  display: none;
}

.relatedVideos .video-wrapper, .relatedVideos .video-wrapper img {
  float: left;
  background: #eee;
  display: block;
  position: relative;
}

.videoInArticle .relatedVideos .video-wrapper, .videoInArticle .relatedVideos .video-wrapper img {
  width: 145px;
  height: 82px;
}

.articleVideoTop .relatedVideos .video-wrapper, .articleVideoTop .relatedVideos .video-wrapper img {
  width: 205px;
  height: 115px;
}

.relatedVideos .video-wrapper {
  overflow: hidden;
}

.relatedVideos .video-wrapper.spaceImg {
  margin: 0 2px 2px 0;
}

.relatedVideos .video-wrapper.spaceImgBig {
  margin: 0 3px 2px 0;
}

.relatedVideos .video-wrapper.spaceImgRight {
  margin: 2px 0 0;
}

.videoInArticle .relatedVideos .video-wrapper.bigImg, .videoInArticle .relatedVideos .video-wrapper.bigImg img {
  width: 292px;
  height: 166px;
}

.articleVideoTop .relatedVideos .video-wrapper.bigImg, .articleVideoTop .relatedVideos .video-wrapper.bigImg img {
  width: 412px;
  height: 232px;
}

.relatedVideos .video-wrapper.bigImg h3.title-hidden {
  top: 96px;
}

.relatedVideos h3.title-hidden {
  z-index: 1000;
  background: rgba(45, 45, 45, .9);
  padding: 5px;
  line-height: 18px;
  transition: all .5s ease-out;
  position: absolute;
  left: 0;
}

.videoInArticle .relatedVideos h3.title-hidden {
  width: 135px;
  height: 72px;
  top: 82px;
}

.articleVideoTop .relatedVideos h3.title-hidden {
  width: 195px;
  height: 105px;
  top: 115px;
}

.relatedVideos h3.title-hidden a {
  background-color: rgba(0, 0, 0, 0);
  height: 75px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 18px;
  text-decoration: none;
  transition: all .5s ease-out;
  display: block;
  overflow: hidden;
  color: #fff !important;
}

.videoInArticle .relatedVideos h3.title-hidden a {
  width: 282px;
  height: 75px;
  font-size: 12px;
}

.articleVideoTop .relatedVideos h3.title-hidden a {
  width: 195px;
  height: 108px;
  font-size: 14px;
}

.articleVideoTop .relatedVideos .bigImg h3.title-hidden a {
  width: 402px;
  font-size: 16px;
}

.relatedVideos h3.title-hidden a:hover {
  text-decoration: none;
  color: #fff !important;
}

.videoInArticle .relatedVideos .bigImg h3.title-hidden a {
  font-size: 14px;
}

.videoInArticle .relatedVideos .bigImg h3.title-hidden {
  width: 282px;
  height: 60px;
  top: 96px;
}

.articleVideoTop .relatedVideos .bigImg h3.title-hidden {
  width: 412px;
  height: 80px;
  top: 142px;
}

.relatedVideos .video-wrapper:hover .title-hidden {
  color: #fff;
  top: 0;
}

.relatedVideos .bigImg.video-wrapper:hover .title-hidden {
  color: #fff;
}

.videoInArticle .relatedVideos .bigImg.video-wrapper:hover .title-hidden {
  top: 96px;
}

.articleVideoTop .relatedVideos .bigImg.video-wrapper:hover .title-hidden {
  top: 142px;
}

.relatedVideos h3.title-hidden .boxCategory, .relatedVideos h3.title-hidden .boxDate {
  float: left;
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
}

.relatedVideos h3.title-hidden .boxDate {
  float: right;
}

.relatedVideos .bigImg h3.title-hidden .boxCategory, .relatedVideos .bigImg h3.title-hidden .boxDate {
  display: none;
}

#gdpr-form textarea {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #a9a9a9;
  width: 100%;
  padding: 2px 5px;
}

#gdpr-form .gdpr-form__btns-wrapper {
  text-align: left;
}

#gdpr-form .gdpr-form__btns-wrapper .login-button {
  text-align: center;
  vertical-align: unset;
  min-width: 93px;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
}

#gdpr-form .gdpr-form__btns-wrapper {
  text-align: center;
  padding-top: 10px;
}

#gdpr-form .gdpr-form__btns-wrapper .login-button {
  width: 93px;
  margin: 10px auto 0;
  display: block;
}

